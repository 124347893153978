<template>
  <v-layout wrap v-if="item">
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.Name"
        label="Nombre"
        outlined
      />
    </v-flex>
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.Parrilla"
        label="Parrilla"
        outlined
      />
    </v-flex>
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.Banner"
        label="Banner"
        outlined
      />
    </v-flex>
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.Message"
        label="Message"
        outlined
      />
    </v-flex>
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.Webapp"
        label="Webapp"
        outlined
      />
    </v-flex>
    <v-flex xs12>
      <div style="padding: 0 10px 10px 10px;">
        <small style="color: rgba(0, 0, 0, 0.87);">Colores: </small>
      </div>
      <color-picker-item
        :value="editedItem.BGColor"
        label="Fondo panel derecho"
        :onChange="handleChangeBGColor"
        borderRadius="20px 0 0 0"
      />
      <!--v-text-field
        v-model="editedItem.BGColor"
        label="Fondo panel derecho"
        outlined
      /-->
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="editedItem.Color"
        label="Texto panel derecho"
        :onChange="handleChangeColor"
        borderRadius="0 0 0 0"
      />
      <!--v-text-field
        v-model="editedItem.Color"
        label="Texto panel derecho"
        outlined
      /-->
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="editedItem.BGColorMessage"
        label="Fondo message"
        :onChange="handleChangeBGColorMessage"
        borderRadius="0 0 0 0"
      />
      <!--v-text-field
        v-model="editedItem.BGColorMessage"
        label="Fondo message"
        outlined
      /-->
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="editedItem.ColorMessage"
        label="Texto message"
        :onChange="handleChangeColorMessage"
        borderRadius="0 0 0 0"
      />
      <!--v-text-field
        v-model="editedItem.ColorMessage"
        label="Texto message"
        outlined
      /-->
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="editedItem.BGColorParrilla"
        label="Fondo parrilla"
        :onChange="handleChangeBGColorParrilla"
        borderRadius="0 0 0 0"
      />
      <!--v-text-field
        v-model="editedItem.BGColorParrilla"
        label="Fondo parrilla"
        outlined
      /-->
    </v-flex>
    <v-flex xs12>
      <color-picker-item
        :value="editedItem.BGColorBanner"
        label="Fondo banner"
        :onChange="handleChangeBGColorBanner"
        borderRadius="0 0 20px 0"
      />
      <!--v-text-field
        v-model="editedItem.BGColorBanner"
        label="Fondo banner"
        outlined
      /-->
    </v-flex>
    <v-flex xs12 style="padding: 20px 0;">
      <v-text-field
        v-model="editedItem.Time"
        label="Time"
        outlined
      />
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        v-model="editedItem.Debug"
        label="Debug"
      />
    </v-flex>
    <v-flex xs12>
      <action-buttons
        v-if="editedItem"
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import ActionButtons from '@/components/ActionButtons'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  components: {
    ActionButtons, 
    ColorPickerItem,
  },
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    item: null,
  }),
  computed: {
    isMobile () {
      return utils.isMobile()
    },
    environment () {
      return 'players'
    },
  },
  watch: {
    'editedItem' () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.item = JSON.parse(JSON.stringify(this.editedItem))
    },
    prepareValue (v) {
      return v && v.length > 0 ? v : null
    },
    handleSave () {
      const url = 'v1/private/chromecast/'

      this.editedItem.Parrilla = this.prepareValue(this.editedItem.Parrilla)
      this.editedItem.Banner = this.prepareValue(this.editedItem.Banner)
      this.editedItem.Message = this.prepareValue(this.editedItem.Message)
      this.editedItem.Webapp = this.prepareValue(this.editedItem.Webapp)

      if (this.editedItem.ID)
        api.updateItem (this.environment, url, this.editedItem.ID, this.editedItem)
          .then(() => {
            this.onClose(true)
          })
      else
        api.addItem (this.environment, url, this.editedItem)
          .then(() => {
            this.onClose(true)
          })
    },
    handleDelete () {
      const url = 'v1/private/chromecast/'
      api.deleteItem (this.environment, url, this.editedItem.ID)
        .then(() => {
          this.onClose(true)
        })
    },
    handleChangeBGColor (v) {
      this.editedItem.BGColor = v
    },
    handleChangeColor (v) {
      this.editedItem.Color = v
    },
    handleChangeBGColorMessage (v) {
      this.editedItem.BGColorMessage = v
    },
    handleChangeColorMessage (v) {
      this.editedItem.ColorMessage = v
    },
    handleChangeBGColorParrilla (v) {
      this.editedItem.BGColorParrilla = v
    },
    handleChangeBGColorBanner (v) {
      this.editedItem.BGColorBanner = v
    },
  },
}
</script>
